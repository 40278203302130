/*
Template Name: Wrapkit
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/*==============================================================
 You can write your style here and overwrite our style
 ============================================================== */
 /*******************
Header 11
*******************/
.h11-nav {
  padding: 0; }
  .h11-nav .navbar-brand {
    padding: 10px 0; }
  .h11-nav .h11-topbar ul {
    margin-bottom: 0px;
    padding: 15px 0; }
    .h11-nav .h11-topbar ul li {
      display: inline-block;
      vertical-align: middle; }
      .h11-nav .h11-topbar ul li a {
        padding: 0px 7px;
        color: #8d97ad;
        text-decoration: none;
        display: block; }
      .h11-nav .h11-topbar ul li:hover a {
        color: #263238; }
      .h11-nav .h11-topbar ul li:last-child a {
        font-size: 21px;
        font-weight: 600;
        color: #20a7ea;
        font-family: -apple-system, system-ui, Roboto, sans-serif;
        padding-left: 15px;
        margin-left: 7px;
        border-left: 1px solid rgba(120, 130, 140, 0.13); }
  .h11-nav .navbar-nav .nav-item {
    margin: 0 20px; }
    .h11-nav .navbar-nav .nav-item .nav-link {
      padding: 12px 0px;
      color: #803713;
      border-bottom: 1px solid transparent;
      font-weight: 600;
      font-family: -apple-system, system-ui, Roboto, sans-serif;
      text-transform: uppercase; }
    .h11-nav .navbar-nav .nav-item:hover .nav-link, .h11-nav .navbar-nav .nav-item.active .nav-link {
      color: #188ef4;
      border-bottom: 1px solid #188ef4; }
    .h11-nav .navbar-nav .nav-item:last-child {
      margin-right: 0px; }

@media (max-width: 1023px) {
  .h11-nav .navbar-nav .nav-item {
    margin: 0; } }

.no-spacer {
    padding: 0px;
}

.subscribe-box input::placeholder{
    color:rgb(132, 131, 131);
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}

.shadow-top-left {
    -webkit-box-shadow: -12px -12px 5px -2px rgba(148, 148, 148, 1);
    -moz-box-shadow: -12px -12px 5px -2px rgba(148, 148, 148, 1);
    box-shadow: -12px -12px 5px -2px rgba(148, 148, 148, 1);
}

.shadow-bottom-left {
    -webkit-box-shadow: -12px 12px 5px -2px rgba(148, 148, 148, 1);
    -moz-box-shadow: -12px 12px 5px -2px rgba(148, 148, 148, 1);
    box-shadow: -12px 12px 5px -2px rgba(148, 148, 148, 1);
}

.shadow-top-right {
    -webkit-box-shadow: 12px -12px 5px -2px rgba(148, 148, 148, 1);
    -moz-box-shadow: 12px -12px 5px -2px rgba(148, 148, 148, 1);
    box-shadow: 12px -12px 5px -2px rgba(148, 148, 148, 1);
}

.blog-home6 {
    background-color: #d8e8f1;
}

.blog-home6 a {
    color: #3e4555;
}

.img-fertilizer {
    max-height: 280px;
    width: auto;
    -webkit-filter: drop-shadow(20px 20px 5px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.3));
    display: block;
    margin: 0 auto;
}

.topbar-product {
    padding: 0px;
    padding: 0px;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    width: 100%;
    z-index: 20;
}

.page-product-wrapper {
    padding-top: 0px;
}

.topbar-product.fixed-header {
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background: #188ef4;
}

.topbar-product.fixed-header .navbar .navbar-brand img {
    height: 50px; // -webkit-backface-visibility: hidden;
    // -webkit-transform: translateZ(0) scale(1, 1);
}

.topbar.fixed-header {
    -webkit-box-shadow: 0px 4px 8px -7px rgba(153, 153, 153, 1);
    -moz-box-shadow: 0px 4px 8px -7px rgba(153, 153, 153, 1);
    box-shadow: 0px 4px 8px -7px rgba(153, 153, 153, 1);
}

.text-brown{
    color: #803713;
}

.yt-container{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.yt-video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fixed-header{
    .navbar{
        .navbar-brand{
            img{
                height: 70px
            }
        }
    }
    .h11-topbar{
        ul{
            padding: 15px 0 0 !important;
            li:last-child{
                a{
                    font-size: 18px !important
                }
            }
        }
    }
}

.slide-content {padding:40px 30px;position:relative;}.slide-image{max-height: 700px !important;object-fit: cover;}@media (max-width: 767px){.slider4 .slide-image{height:400px !important}}

.list-inline .fa-facebook-f {
    color: #188ef4;
}

.list-inline .fa-google-plus {
    color: red;
}

.navbar .navbar-brand img {
    height: 90px;
}

.bg-radial-gradient {
    background: radial-gradient(circle, white, #dde0e1);
}

.bg-gradient-promotion {
    background:linear-gradient(45deg, #5390D9, #80FFDB);
}

.bg-gradient-report {
    background: linear-gradient(135deg,#eb1e68 19.57%,#fc6d05 93.6%);
}

.card-product-padding {
    padding: 30px 90px;
}

.drop-shadow {
    -webkit-filter: drop-shadow(20px 20px 5px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.3));
}

.collapse-product {
    position: absolute;
    height: 290px;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 67%,
        rgba(255, 255, 255, 0) 100%
    );
}

// add footer
.subscribe-box {
    background: rgba(120, 130, 140, 0.13);
    padding: 25px;
    border: 1px solid rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    .form-control {
        background: 0 0;
        padding: 10px 0;
        border: 0;
        border-bottom: 1px solid rgba(120, 130, 140, 0.13);
        color: #fff;
        border-radius: 0;
    }
}

[class*='price-']::before{
    display: block;
    font-size: 0.8rem;
}

.price-southern::before{
    content: 'HCM';
}

.price-north::before{
    content: 'Hải Phòng';
}

.price-central::before{
    content: 'Quy Nhơn';
}

section, article, footer{
    content-visibility: auto;
}

.f1-brand {
    .logo-brand {
        height: 70px;
        background: white;
        border: 1px solid white;
        border-radius: 10px;
    }
    span {
        padding-left: 15px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 21px;
    }
}

.footer1 {
    .f1-topbar {
        border-bottom: 1px dashed #dde0e1;
    }
    .f1-middle {
        padding: 40px 0px;
    }
    .f1-bottom-bar {
        padding: 10px 0;
        border-top: 1px solid rgba(120, 130, 140, 0.13);
        font-size: 13px;
    }
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #18daf4bd;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    transition: 0.7s ease;
}

.img-ho:hover .overlay {
    transform: scale(1);
}

.btn-share{
    border: 1px solid #e2e2e2;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    cursor: pointer;
    color: #616161;
    .icon-facebook{
        font-size: 0.8rem;
        top:1px;
        position: relative;
    }
    .icon-zalo{
        font-size: 2.3rem;
        position:relative;
        top:-6px;
        left: -6px;
    }
}

.bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(32, 35, 43, 0.8);
}

.img-overlay::after{
    position: absolute;
  content:"";
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: black;
  opacity:0.5;
  z-index: -2;
}

/* Some text inside the overlay, which is positioned in the middle vertically and horizontally */

.product-text {
    color: white;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.text-light-blue {
    color: #4fc3f7 !important;
}

.text-orange {
    color: #f57e22;
}

.navbar {
    padding: 5px 0px;
}

.footer1 .navbar-brand {
    color: white;
}

#ft1 .nav-link {
    color: #8d97ad;
}

/*******************
Feature 21
*******************/
.wrap-feature21-box {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

ol.carousel-indicators{
    list-style: none;
}

@keyframes imagescale {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
		-moz-transform: scale(1.0);
		-o-transform: scale(1.0);
    }
    100% {
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
		-moz-transform: scale(1.3);
		-o-transform: scale(1.3);
    }
}

.slider4 {
	.slide-image {
		animation: imagescale 15s ease-in-out infinite alternate;
		-webkit-animation: imagescale 15s ease-in-out infinite alternate;
		-moz-animation: imagescale 15s ease-in-out infinite alternate;
		-o-webkit-animation: imagescale 15s ease-in-out infinite alternate;
	}
}
.sidebar-sticky{
    position: -webkit-sticky;
    position: sticky;
    top:100px
}

.timeline {
    li {
        .timeline-badge-large {
            width: 80px;
            height: 80px;
            margin-left: -40px;
            z-index: 10;
            position: absolute;
            top: 16px;
            left: 50%;
            border-radius: 50% 50% 50% 50%;
            text-align: center;
            font-size: 1.4em;
            line-height: 46px;
            color: #fff;
            overflow: hidden;
        }
    }
}

strong {
    font-weight: 500;
}

.feature15 .quote {
    top: -40px;
    left: -40px;
}

.breadcrumb {
    background-color: transparent;
    padding: 0 0 15px 0;
    font-size: 1rem;
}

.list-inline .fa-youtube {
    color: red;
}

.form-control {
    height: revert;
}

article {
    img {
        max-inline-size: 100%;
        block-size: auto;
        width: 100%;
        height: auto;
    }

    video{
        max-inline-size: 100%;
    }
}

.link-underline {
    text-decoration: underline;
}

.slide-image{
    max-height: 500px;
    object-fit: cover;
}

.bg-purple-gradiant {
    background: #7f3baf;
    background: -webkit-linear-gradient(legacy-direction(to right), #7f3baf 0%, #4d3f95 100%);
    background: -webkit-gradient(linear, left top, right top, from(#7f3baf), to(#4d3f95));
    background: -webkit-linear-gradient(left, #7f3baf 0%, #4d3f95 100%);
    background: -o-linear-gradient(left, #7f3baf 0%, #4d3f95 100%);
    background: linear-gradient(to right, #7f3baf 0%, #4d3f95 100%); 
}
  
.bg-theme-gradiant {
    background: #edb610;
    background: -webkit-linear-gradient(legacy-direction(to right), #edb610 0%, #ff8e49 100%);
    background: -webkit-gradient(linear, left top, right top, from(#edb610), to(#ff8e49));
    background: -webkit-linear-gradient(left, #edb610 0%, #ff8e49 100%);
    background: -o-linear-gradient(left, #edb610 0%, #ff8e49 100%);
    background: linear-gradient(to right, #edb610 0%, #ff8e49 100%); 
}

.bg-red-gradiant{
    background: #ed0030;
    background: -webkit-linear-gradient(legacy-direction(to right), #ed0030 0%, #ff8e49 100%);
    background: -webkit-gradient(linear, left top, right top, from(#ed0030), to(#ff8e49));
    background: -webkit-linear-gradient(left,#ed0030 0%, #ff8e49 100%);
    background: -o-linear-gradient(left,#ed0030 0%, #ff8e49 100%);
    background: linear-gradient(to right,#ed0030 0%, #ff8e49 100%); 
}

.bg-green-blue{
    background-image: linear-gradient(to right bottom, #00ddf2, #00d1e8, #00c5dd, #00b9d3, #00adc8);
}
.bg-black-gradiant{
    background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
}

.text-red-gradiant{
    @include linear-gradient (to right, #ed0030 0%, #ff8e49 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color:transparent
}

.wrap-feature6-box hr{
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.wrap-feature2-box h4{
    font-size: 2vw;
}

/*******************
Feature 30
*******************/

.feature30 {
  position: relative;
}
.wrap-feature30-box {
  position: absolute;
}

@media(min-width:1024px){
  .wrap-feature30-box {
    top: 20%;
    right: 0px;
  }
}

@media (max-width:415px){
  .wrap-feature30-box{
    position: relative;
  }
}

/*******************
Feature 31
*******************/

@media (min-width: 991px) {
    .feature31 {
        padding:90px 0
    }
}

.wrap-feature31-box {
    overflow: hidden;
    position: relative;
    .half-radius{
        border-radius: 62% / 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; 
    }
    
    .right-image{
        position: absolute;
        right: 0px;
        text-align: center;
        bottom: -60px;
    }
}

/*******************
slide 1
*******************/
  
.slider1 {
    position: relative;
  h2 {
      display: inline-block;
      color: $white;
      font-weight: 800;
      font-size: 36px;
      padding: 20px;
      text-transform: uppercase;
      margin: 0;
  }
  p {
      font-size: 18px;
      a {
          border-radius: 0;
          box-shadow: none;
          color: $bodytext;
          padding-left: 20px;
          padding-right: 60px;
          i {
              opacity: 1;
              right: -2rem;
              color: $success;
          }
          &:hover {
              color: $dark;
              span {
                  transform: translate3d(0, 0, 0);
              }
              i {
                  right: -2.7rem;
                  color: $success;
              }
          }
      }
  }
  .slide-text {
    margin: auto;
    position: absolute;
    text-align: left;
    max-width: 1170px;
    padding: 0 15px;
    top: 50%;
    left: 50%;
    right: auto;
    width: 100%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%)
}
.slide-image {
    margin: auto;
    width: 100%;
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}
}
  
  @media (max-width: 767px) {
      .slider1 {
          .slide-image{min-height:300px; }
          h2 {
              font-size: 20px;
              
          }
          p {
              a {
                  font-size: 14px;
              }
          }
      }
  }

  @media (max-width: 767px) {
      .slider1 {
          .slide-image{min-height:300px; }
          h2 {
              font-size: 20px;

          }
          p {
              a {
                  font-size: 14px;
              }
          }
      }
  }

/*******************
slide 9
*******************/
.slider9 {
	p {
		color:#b8d0ff;
		
		a {
			text-decoration:underline;
			
			&:hover {
				text-decoration:none;
			}
		}
	}
	.carousel-indicators {
		right:auto;
		margin-left:11%;
		margin-right:11%;
	}
	.btn {
		border:1px solid #fff;
	}
	.col-lg-9 {
		max-width:400px;
	}

    .slide-text {
        margin: auto;
        position: absolute;
        text-align: left;
        max-width: 1170px;
        padding: 0 15px;
        top: 50%;
        left: 0%;
        right: auto;
        width: 100%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%)
    }

    .slide-image {
        margin: auto;
        width: 100%;
        -webkit-animation-delay: .3s;
        animation-delay: .3s
    }
}
@media (max-width: 1100px) {
	.slider9 {
		.col-md-11 {
			min-width:355px;
		}
		p {
			font-size:14px;
		}
		.carousel-indicators {
			right:0;
		}
	}
}
@media (max-width: 992px) {
	.slider9 {
		.col-md-11 {
			min-width:250px;
		}
		h2 {
			font-size:22px;
			line-height:30px;
		}
	}
}
@media (max-width: 767px) {
	.slider9 {
		.bs-slider, .carousel-item {
			max-height:100% !important;
		}
		.slide-text {
			position:relative;
		}
		.col-md-11 {
			padding:25px 15px 50px;
		}
		.col-lg-9 {
			max-width:100%;
		}
		.carousel-indicators {
			bottom:-5px;
		}
	}
}
@media (max-width: 320px) {
	.slider9 {
		h2 {
			font-size:17px;
			line-height:26px;
		}
	}
}
@media (min-width: 1900px) and (max-width: 2700px) {
	.slider9 {
		.carousel-indicators {
			margin-left:20%;
		}
	}
}
@media (min-width: 1970px) and (max-width: 2700px) {
	.slider9 {
		.slide-text {
			transform:translate(-50%, -100%) !important;
			-webkit-transform:translate(-50%, -100%) !important;
			-moz-transform:translate(-50%, -100%) !important;
		}
		.carousel-indicators {
			margin-left:26%;
		}
	}
}

@media (max-width: 767px) {
    .navbar .navbar-brand img {
        background-color: white;
        border-radius: 10px;
        height: 70px;
    }
    .h11-nav .h11-topbar ul li a {
        color: white !important;
    }
    .h11-nav .navbar-nav .nav-item .nav-link {
        font-size: 0.8rem;
        color: white !important;
    }
    .list-inline .fa-facebook-f {
        color: white !important;
    }
    .wrap-feature31-box,
    .contact4,
    .form6 {
        .right-image {
            position: relative;
            bottom: -95px;
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 834px){
    .card-product-padding{
        padding: 30px 50px;
    }
}

@media (min-width:991px){
    .feature31{
        padding: 90px 0;
    }
}

@media (max-width: 768px){
    .navbar-toggler{
        color: white
    }
}

@media (max-width: 576px) {
    .navbar .navbar-brand img {
        background-color: white;
        border-radius: 8px;
        height: 50px !important;
 
    }

    .wrap-feature2-box{
        h4{
            font-size: 6vw
        }
    }
}

