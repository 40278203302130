/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets 
 *	8. Custom Templates
 */

@import '_variables.scss';
@import '../css/animate.css'; 
// @import '../icons/font-awesome/scss/fontawesome.scss'; 
// @import '../icons/font-awesome/scss/solid.scss'; 
// @import '../icons/font-awesome/scss/regular.scss'; 
// @import '../icons/icomoon/style.css';
// @import '../icons/themify-icons/themify-icons.css';
// @import '../icons/simple-line-icons/css/simple-line-icons.css';
// @import 'spinner.scss'; 
@import 'app.scss';  
@import 'common.scss'; 
@import 'responsive.scss';   
@import 'yourstyle.scss';   

//This page plugin css
// owl.carousel This is for the owl carousel CSS
// @import '~owl.carousel/dist/assets/owl.theme.green.min.css';
// @import '../js/bootstrap-touch-slider/bootstrap-touch-slider.css'
